.ant-tag {
  display: flex;
  align-items: center;
  margin-right: 0.75rem;
  margin-bottom: 0.5rem;
}

.ant-pagination span {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-pagination .ant-pagination-item-active {
  border: none;
  background-color: #2676d9;
}
.spiner {
  margin: 120px 0;
  margin-bottom: 120px;
  padding: 130px 150px;
  text-align: center;
  border-radius: 14px;
}

.ant-pagination .ant-pagination-item-active a {
  color: white;
}

.custom-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 2px;
  cursor: pointer;
  color: black;
  border: 1px solid #d9d9d9;
  outline: none;
}

.custom-pagination.disabled {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
}

.custom-pagination:not(.disabled):hover {
  border-color: #1890ff;
  color: #1890ff;
}

.custom-pagination.custom-first {
  margin-right: 8px;
}

.custom-pagination.custom-last {
  margin-left: 8px;
}

.mobile-filter-car {
  width: calc(100vw - 10px);
  left: 5px;
}

.loader {
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #3498db;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
.sticky-nav{
  .mt-2{
    position: sticky;
    top:150px;
    margin-bottom:112px;
    @media(max-width:768px){
      position:initial;
      
    }
  }
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.step-1 {
  .finance-cash-tabs {
    .ant-tabs-nav-list {
      @media (max-width: 420px) {
        display: block;
      }
    }

    .ant-tabs-nav-list .ant-tabs-tab {
      font-size: 1rem;
      font-weight: normal;
      border: none !important;
      @media (max-width: 420px) {
        width: 100%;
      }
    }
  }
}

.mobile-step {
  overflow: auto;
  &::-webkit-scrollbar {
    height: 3px;
    width: 10%;
  }
}

.find-your-dealership {
  .top-search-box {
    top: 320px;
    left: 12rem;
  }

  .claim-arrow-bg {
    background-image: url('../../assets/claim-dealership-arrow.png');
    display: inherit;
    align-items: start;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
    height: 130px;
  }

  .ant-input {
    width: 553px;
  }

  .find-dealership-mobile {
    .claim-arrow-bg {
      height: 113px;
    }
    .ant-input {
      width: 330px;
    }
  }

  .ant-input:placeholder-shown {
    font-style: italic;
    font-size: 12px;
  }

  .desktop.banner {
    height: calc(240vh - 200px);
  }

  .mobile.banner {
    height: auto;
  }

  .banner {
    display: flex;
    align-items: start;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom right;
  }

  .banner.hidden {
    display: none;
  }

  .banner-alignment {
    margin-left: 5vw !important;
    margin-top: 2.5vw !important;
  }

  .banner-background1 {
    background-image: url('../../assets/jpg/claimDealership.jpg');
  }

  .with-check {
    display: inline;
  }

  .lookup-table {
    max-width: 100% !important;
    bottom: 259px;
  }

  .ant-select-selector {
    width: 312px !important;
  }

  .look-up-left {
    line-height: 50px;
  }

  .lookup-arrows {
    bottom: -119px;
    left: 92px;
  }

  .turn-to-5xl {
    font-size: 3rem;
  }

  .look-up-right .line-height {
    line-height: 38px;
  }

  .ant-select-selection-placeholder {
    text-align: left;
    font-style: italic;
    font-size: 12px;
  }

  .lookup-counter .ant-btn {
    height: 59px;
  }

  .mobile {
    .ant-select-selector {
      width: 312px !important;
    }
  }

  @media (max-width: 1280px) {
    .ant-input {
      width: 400px;
    }

    .lookup-table {
      bottom: 109px;
    }

    .ant-select-selector {
      width: 250px !important;
    }

    .look-up-left {
      line-height: 38px;
    }

    .lookup-arrows {
      bottom: -153px;
      left: inherit;
    }

    .turn-to-5xl {
      font-size: 2.25rem;
    }
  }
}

.claim-your-business {
  .step-number {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 36px;
    min-width: 36px;
    min-height: 36px;
    border-radius: 50%;
    background-color: #cfd3df;
    color: white;
    font-size: 20px;
    font-weight: 700;
  }

  .ant-progress-bg {
    background-color: #2777da;
  }

  .field-label {
    font-size: 18px;
    font-weight: 700;
  }

  .text-primary-color {
    color: #2777da;
  }

  .text-secondary-color {
    color: #848495;
  }

  .ant-radio-inner {
    width: 22px;
    height: 22px;
  }

  .ant-radio-checked .ant-radio-inner {
    background-color: #2777da;
  }

  .ant-radio-inner:after {
    background-color: #ffffff;
  }

  .ant-select-selector {
    border-radius: 12px !important;
  }

  .ant-form .ant-form-item .ant-form-item-control,
  .ant-form .ant-form-item .ant-form-item-label {
    flex: 0 0 auto;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 50px;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 50px;
  }

  .step-icon {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }

  .radio-group {
    & > span:nth-child(2) {
      width: 100%;
    }
    width: 100%;
  }

  .custom-form-email {
    .ant-form-item-control {
      position: relative;
    }
    .ant-form-item-explain {
      position: absolute;
      top: 100%;
    }
  }
}

.dealer-logo {
  float: left;
  margin-top: -20px;
  margin-bottom: 20px;
  width: 60%;
}

.mobile.dealer-logo {
  width: auto;
  max-width: inherit;
  height: 56px;
}

.dealer-logo.mobile-menu-logo {
  width: 80%;
}

.dealership-logo-desktop {
  float: left;
  margin-top: -20px;
  margin-bottom: 20px;
  width: 60%;
  max-height: 56px;
}

.dealership-logo-mobile {
  width: auto;
  max-width: inherit;
  height: 56px;
}

.control-arrow {
  top: 0 !important;
  margin-top: 0 !important;
  height: 100%;
  border: 1px solid #c4c4c4 !important;
  border-radius: 3px !important;
  background-color: #f7f8f8 !important;

  &.control-prev {
    &:before {
      border-right-color: #868686 !important;
    }
  }
  &.control-next {
    &:before {
      border-left-color: #868686 !important;
    }
  }
}

.carousel-slider {
  .control-arrow {
    display: none;
  }
}

.thumbs-wrapper {
  .thumb {
    &:hover {
      border: 1px solid #2676d9 !important;
      border-radius: 3px;
    }
  }

  .selected {
    border: 1px solid #2676d9 !important;
    border-radius: 3px;
  }
}

.btn-heart {
  border-color: black !important;
  color: black;

  &:hover,
  &:focus {
    background-color: transparent;
    color: black;
  }
}

.history-content {
  .history-row {
    &:last-child {
      border: 0;
    }
  }
}
.landscape-mode {
  @media only screen and (max-device-width: 923px) and (orientation: landscape) {
    display: block !important;
  }
}

.customer-modal {
  .ant-modal-content {
    border-radius: 12px;
  }

  .anticon-close {
    color: #2168ba !important;
    font-weight: 700 !important;
  }
}

.ant-tooltip-inner {
  color: black;
  background-color: #fff;
  padding: 12px;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  border-bottom-color: #fff;
}

.ant-tooltip-arrow-content::before,
.ant-tooltip-arrow-content:before {
  background-color: #fff !important;
}

.ant-tooltip-arrow-content {
  background-color: #fff !important;
  width: 10px;
  height: 10px;
}

.customer-details {
  .field-name {
    color: rgba(0, 0, 0, 0.25);
    font-size: 11px;
  }

  .field-name.dealership {
    color: #666;
    font-size: 12px;
  }

  .field-name.customer {
    color: #2168ba !important;
    font-size: 12px;
  }

  .field-value {
    font-size: 12px;
  }

  .customer-name {
    font-size: 20px;
    font-weight: bold;
  }
}

.close-line {
  line-height: 14px;
}

.customer-modal-actions button {
  font-size: 11px !important;
  color: #2168ba !important;
}

.mobile-menu-logo {
  width: 80%;
}

.mobile-nav-menu {
  margin-top: -10px;
}

.fixed-footer {
  border-top: 2px solid #2676d9;
}

.footer-logo {
  height: 50px;
  float: left;
  margin-top: 10px;
  /* margin-left: -204px; */
  max-width: inherit;
}

.mobile.footer-logo {
  float: none;
  margin: 0 50px 0 50px;
}

.main-footer {
  @media (min-width: 768px) {
    background-position: bottom right;
    background-repeat: no-repeat;
  }
}

@media (max-width: 1280px) {
  .container {
    max-width: 1140px !important;
  }
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  /* For tabs border */
  border-color: black;
}

.ant-tabs-top > .ant-tabs-nav::before {
  /* For the line to the right and close to the tabs */
  border-color: black;
}

.ant-tabs > .ant-tabs-nav {
  /* So that there is no gap between the content and tabs */
  margin-bottom: 0;
}

.ant-tabs-tab {
  padding: 0 15px;
}
.ant-tabs-tab + .ant-tabs-tab {
  margin-left: 0;
}

.ant-tabs-tab-active {
  border-right: 1px solid #000;
  border-left: 1px solid #000;
  border-top: 1px solid #000;
}

.ant-tabs-ink-bar {
  background: white;
}

.top-search-box {
  top: 400px;
  left: 6rem;
}

.desktop.banner {
  height: calc(100vh - 100px);
}

.mobile.banner {
  height: auto;
}

.banner {
  display: flex;
  align-items: start;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom right;
}
.baner-alignment {
  margin-left: 5vw !important;
}

.banner-background1 {
  background-image: url('../../assets/jpg/banner1New.jpg');
}

.with-check {
  display: inline;
}

.testimonials {
  .testimonial-slider {
    .slick-prev {
      left: -50px !important;
    }

    .slick-next {
      right: -40px !important;
    }
  }

  .testimonial-body {
    font-size: 15px;
  }
}

.testimonials.hidden {
  display: none !important;
}

@media (max-width: 1280px) {
  .top-search-box {
    top: 245px;
    left: 5rem;
  }

  .buying-made-simple {
    .text-7xl {
      font-size: 4rem;
    }

    .text-2xl {
      font-size: 1.25rem;
    }
  }
}

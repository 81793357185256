.financing-status-modal {
  .ant-modal-header {
    border-radius: 12px 12px 0 0;
    padding: 20px 24px;
    background: #f5f5f5;
  }

  .ant-modal-content {
    border-radius: 12px;
  }

  .ant-modal-title {
    font-size: 20px;
  }

  .anticon-close {
    color: #2168ba !important;
    font-weight: 700 !important;
  }

  .financing-field {
    line-height: 32px !important;
  }

  .ant-select-selector,
  .financing-field {
    border-radius: 12px !important;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 50px;
  }

  .ant-select-selector .ant-select-selection-placeholder,
  .ant-select-selection-item {
    line-height: 48px !important;
    font-size: 16px;
  }

  .ant-select-item.ant-select-item-option {
    font-size: 16px;
  }

  .w-48.ant-select-single {
    width: 12rem;
  }

  .ant-input-number-handler-wrap {
    top: 1px;
    right: 4px;
    height: 95%;
  }

  .ant-form-item-explain-error {
    font-size: 11px;
  }
}

.financing-status-modal.financing-terms {
  .ant-modal-title {
    font-size: 16px;
  }

  .ant-modal-body {
    font-size: 12px;
  }
}

.ant-table-thead > tr > th {
  position: relative;
  color: black;
  font-weight: 500;
  text-align: left;
  background: white;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s ease;
  opacity: 50%;
}

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: #e9f2fb;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: transparent !important;
  border: 2px solid #b9c0c8 !important;
}

.ant-table-tbody > tr > td.ant-table-cell-row-hover .ant-checkbox-checked .ant-checkbox-inner {
  border: 2px solid #b9c0c8 !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #b9c0c8 !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-checked::after {
  border: 2px solid #b9c0c8 !important;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #b9c0c8 !important;
  left: 18.5% !important;
}

.select-sortby-dashboard {
  font-size: 16px;
  color: rgb(171, 177, 186);
  font-family: 'Myriad Pro';
  line-height: 1.2;
  text-align: left;
  -moz-transform: scale(0.97, 1);
  -webkit-transform: scale(0.97, 1);
  -ms-transform: scale(0.97, 1);
}

.admin-dealers {
  .ant-table-tbody {
    padding: 0 !important;
  }
  .ant-table-tbody > tr > td {
    padding: 0 !important;
  }
}

.ez-dealz-sent {
  .ant-table-tbody > tr > td {
    font-size: 13px;
  }

  .ant-modal-body {
    padding-left: 24px !important;
  }

  .ant-checkbox .ant-checkbox-inner {
    background: transparent;
  }

  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 4px;
    padding-right: 4px;
  }
}

.c-pop {
  font-size: 12px;
}

/*message history */
.chatWindow {
  display: grid;
  grid-column-start: 2;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 190;
  border-radius: 5px;
}
.chat {
  list-style: none;
  background: none;
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 0 0 5px 0;
}
.chat li {
  padding: 0 0.5rem;
  overflow: hidden;
}
.other {
  padding: 5px;
  order: 1;
  border-radius: 0px;
}
.other:before {
  content: '';
  position: relative;
  top: 0px;
  right: 0px;
  left: 40px;
  width: 0px;
  height: 0px;
  border-left-color: transparent;
  border-right-color: transparent;
}
.other:nth-child(odd) .msg {
  background-color: #fbfcfc;
}
.other:nth-child(even) .msg {
  background: #fdfefe;
}

.send-email-modal {
  min-width: 600px;
}

.self {
  padding: 5px;
  order: 1;
  border-radius: 0px;
  justify-content: flex-end;
  align-items: flex-end;
}

.self:nth-child(odd) .msg {
  background-color: #e1f5fe;
}
.self:nth-child(even) .msg {
  background: #ebf5fb;
}
.msg {
  min-width: fit-content;
  padding: 10px;
}

.message-subject {
  font-size: small;
  font-weight: bold;
}

.message-comments {
  font-size: medium;
}
.unread-message-row {
  background-color: #ff6868;
  .ant-table-column-sort {
    background: transparent;
  }
}
.replay-name {
  color: #40a9ff;
}

.buttons {
  .ant-btn-primary {
    background: #fff !important;
    color: #000 !important;
    border-color: #ccc;
    margin-left: 25px;
    @media (max-width: 450px) {
      margin-left: 0;
      margin-right: 0;
    }
    &.active {
      background-color: #40a9ff !important;
      color: #fff !important;
    }
  }
  @media (max-width: 450px) {
    flex-direction: column;
  }
}

.new-inbound-lead-status {
  border-width: 2px;
  border-style: solid;
  border-radius: 10px;
  margin-right: 8px;
  background-color: #ffffff;
  padding: 0.5px 1.5px;
}

.smartboard-status {
  border-width: 3px;
  border-style: solid;
  border-radius: 10px;
  margin-right: 8px;
}

.accepted-switch.ant-switch-checked,
.delivered-switch.ant-switch-checked,
.deposit-switch.ant-switch-checked {
  background-color: rgb(90, 175, 35);
}

.search-dashboard {
  font-family: 'Myriad Pro';
  line-height: 1.2;
  text-align: left;
  -moz-transform: scale(0.97, 1);
  -webkit-transform: scale(0.97, 1);
  -ms-transform: scale(0.97, 1);
  .title {
    font-size: 24px;
    color: rgb(56, 56, 56);
  }
  .subtitle {
    font-size: 16px;
    color: rgb(171, 177, 186);
  }
}

.select-location-dashboard {
  font-size: 16px;
  color: rgb(171, 177, 186);
  font-family: 'Myriad Pro';
  line-height: 1.2;
  text-align: left;
  -moz-transform: scale(0.97, 1);
  -webkit-transform: scale(0.97, 1);
  -ms-transform: scale(0.97, 1);
}

.ant-modal-content {
  border-radius: 20px;
}

.btn-mos-group {
  display: flex;
  width: 100%;

  button {
    border: 1px solid #949599;
    padding: 8px 4px;

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  button:not(:first-child) {
    border-left-width: 0;
  }
}

.vehicle-finance-modal {
  .ant-modal {
    padding: 0;
  }

  .ant-modal-header {
    position: relative;
  }

  .how-border {
    border-color: #f0f0f0;
    border-radius: 10px 10px 0 0;
  }

  .ant-modal-body {
    padding: 0;
    background-color: #f7faf9;
    flex: 1;
  }

  .ant-modal-content {
    min-height: calc(100vh - 40px);
    display: flex;
    flex-direction: column;
  }
}

.finance-box {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    height: 38px;
  }
}

.home-logo {
  float: left;
  margin-top: -20px;
  margin-bottom: 20px;
  width: 60%;
}

.width-240.home-logo {
  width: 240px;
}

.mobile.home-logo {
  width: auto;
  max-width: inherit;
  height: 56px;
}

.ant-modal-content {
  border-radius: 20px;
}
.menu-item:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 5px;
  background-color: #0e6655;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}

.menu-item.active:before,
.menu-item:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

.menu-item {
  padding-top: 0;
  padding-bottom: 10px;
  position: relative;
  margin: 0 0.75rem;
  color: #373d48;
  opacity: 0.8;
  font-weight: 500;
  white-space: nowrap;
}

.menu-item:last-child {
  margin-right: 0;
}

.menu-item.active {
  font-weight: 700;
  opacity: 1;
}

.mobile-menu {
  z-index: 1000 !important;
}

.mobile-menu .active .menu-link {
  background-color: #2676d9;
  color: white;
}
.container {
  &.ml-container {
    @media (max-width: 576px) {
      width: 85%;
    }
    .gap-1 {
      @media (max-width: 370px) {
        flex-direction: column;
        p {
          margin-right: auto;
        }
      }
    }
  }
}

.ez-dealz-account-modal .ant-input-feedback-icon {
  display: flex;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

/* * {
  scroll-behavior: smooth;
} */

@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #d4d4d4;
}

::-webkit-scrollbar-thumb {
  background: #898989;
}

.ReactModal__Overlay {
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}

button {
  outline: none;
}

.ant-checkbox .ant-checkbox-inner {
  background-color: #b9c0c8;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-checked::after {
  border-color: #2676d9;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #2676d9;
  border-color: #2676d9;
}

.back-top {
  overflow: hidden;
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 20px;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 100;
  border: none;
  transition: opacity 0.3s;
  opacity: 0;
  width: 0;
  height: 0;
  padding: 0;
}

.back-top.show-btn {
  opacity: 1;
  width: 40px;
  height: 40px;
}

.back-top:hover,
.back-top:focus {
  background-color: rgba(0, 0, 0, 0.85);
  color: white;
}

@media print {
  .print-only {
    display: block;
  }

  .print-page-container {
    page-break-inside: avoid;
  }
}
@media screen {
  .print-only {
    display: none;
  }
}

.termsPrivacyContainer h1 {
font-size: 36px!important;
}

.termsPrivacyContainer h3 {
  font-size: 24px!important;
} 
.termsPrivacyContainer h1,h2,h3 {
  margin-top: 20px!important;
  margin-bottom: 10px!important;
}

.termsPrivacyContainer ul {
  list-style: inside!important;
  margin: 0;
  padding: 0;
  margin-top: 0;
  margin-bottom: 10px!important;
}

.termsPrivacyContainer {
  padding: 50px;
}
.termsPrivacyContainer .fl-heading {
  text-align: center;
}
.termsPrivacyContainer p { 
margin: 0 0 10px;
}
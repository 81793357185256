.vehicle-finance-start-footer {
  top: unset;
  bottom: 0;

  animation-name: slide-bottom-to-top;
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}

.vehicle-finance-back-footer {
  animation-name: slide-top-to-bottom;
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}

@keyframes slide-bottom-to-top {
  0% {
    bottom: 0;
    top: calc(100% - 92px);
  }
  15% {
    bottom: 0;
    top: 50%;
  }
  30% {
    top: 0;
    bottom: 0;
  }
  60% {
    top: 0;
    bottom: 0;
  }
  80% {
    top: 0;
    bottom: 50%;
  }
  100% {
    top: 0;
    bottom: calc(100% - 92px);
  }
}

@keyframes slide-top-to-bottom {
  0% {
    top: 0;
    bottom: calc(100% - 92px);
  }
  15% {
    top: 0;
    bottom: 50%;
  }
  30% {
    top: 0;
    bottom: 0;
  }
  60% {
    top: 0;
    bottom: 0;
  }
  80% {
    bottom: 0;
    top: 50%;
  }
  100% {
    bottom: 0;
    top: calc(100% - 92px);
  }
}

.step-wrapper {
  max-height: calc(100vh - 112px);
  overflow-y: auto;

  .ant-steps-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .step-title-wrapper {
    p {
      margin-left: 8px;
    }

    @media (max-width: 768px) {
      p {
        font-size: 13px;
      }
    }

    &:first-child {
      p {
        margin-left: 0;
      }
    }
  }
}

.break-box {
  flex-basis: 100%;
  height: 0;
}

.claim-page {
  ::-webkit-scrollbar {
    width: 0px;
  }

  .auth-main-block {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-image: url("../../assets/background-image-login.png");
    background-repeat: repeat;
    background-size: cover;
    background-position: center;
    position: fixed;
    overflow-y: auto;
  }

  .form-user-setup {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 570px;
    height: 650px;
    background: white;
    padding: 40px 75px 50px 75px;
    border-radius: 25px;
    overflow-y: auto;
  }

  .custom-antd-label > .ant-form-item-label > label {
    font-size: 16px;
    font-weight: 600;
  }

  .ant-form .ant-form-item .ant-form-item-control,
  .ant-form .ant-form-item .ant-form-item-label {
    flex: 0 0 auto;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 50px;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 50px;
  }

  input[type="password"],
  input[type="text"] {
    height: 50px;
  }

  .ant-form-item {
    margin-bottom: 10px;
  }

  .claim-your-business {
    background-color: #d5e3f7;
  }

  .copyright {
    bottom: 5px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
  }

  @media (max-width: 1024px) {
    .form-claim {
      height: 600px;
    }
  }

  @media (max-width: 767px) {
    .form-claim {
      width: 340px;
      height: 620px;
      padding: 40px 30px;
    }
  }
}

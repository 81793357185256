.admin-logo {
  float: left;
  margin-top: -20px;
  margin-bottom: 20px;
  width: 60%;
}

.width-240.admin-logo {
  width: 240px;
}

.mobile.admin-logo {
  width: auto;
  max-width: inherit;
  height: 56px;
}

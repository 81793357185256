.justify-between{
    &.claim-delarship{
        flex-direction: column;
        .mb-5{
            width: 100%;
        }
        .sales-container{
            flex-direction: column;
        }
    }
}
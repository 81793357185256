.price-comparison:hover .comparison-content {
  max-height: 180px;
}

.comparison-content {
  max-height: 0;
}

.vehicle-card-entry {
  .vehicle-card-body {
    position: relative;
  }
  .vehicle-card-thumbnail {
    overflow: hidden;
    position: relative;
    display: block;
    padding-top: 75%;
    img {
      transition: 0.3s all ease;
      transform: scale(1);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.loader {
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #3498db;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

.loader-filters {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #67c52e;
  width: 16px;
  height: 16px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

.discount-price-text {
  color: #186cce;
}

.padding-x-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.my-leads-table {
  .ant-table-tbody {
    padding: 0 !important;
  }
  .ant-table-row > td {
    padding: 10px !important;
  }
  .ant-table {
    border-width: 2px;
  }
}

.my-trade-ins-table {
  .ant-table-tbody {
    padding: 0 !important;
  }
  .ant-table-row > td {
    padding: 10px !important;
  }
}
.step-1 {
  .finance-cash-tabs {
    overflow: unset;

    .ant-tabs-nav {
      margin-bottom: 0;
    }

    .ant-tabs-content-holder {
      background-color: white;
      padding: 1rem;
      position: relative;
    }

    .ant-tabs-nav-list .ant-tabs-tab {
      font-size: 1rem;
      font-weight: normal;
      border: none !important;
    }
  }
}
.step-2 {
  @media (max-width: 576px) {
    .ant-row.ant-form-item {
      flex-direction: row !important;
    }
  }
}

.step-5 {
  .equipment-collapse {
    &.ant-collapse {
      background: #f7faf9;
    }

    &.ant-collapse,
    .ant-collapse-content {
      border: none;
      background: #f7faf9;
    }

    .ant-collapse-header,
    .ant-collapse-content-box {
      padding: 15px !important;
    }

    .ant-collapse-header {
      flex-direction: column;
      font-weight: bold;

      .ant-collapse-extra {
        margin-left: unset !important;
        margin-top: 6px;
        font-weight: normal;
      }

      .anticon {
        right: 15px !important;
        top: 20px !important;
        transform: unset !important;
      }
    }

    .ant-collapse-item-active {
      .ant-collapse-extra {
        display: none;
      }
    }
  }
}

.banner {
  display: flex;
  align-items: start;
  height: calc(100vh - 100px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom right;
}

.banner-background-qualified {
  background-image: url("../../assets/get-prequalified.jpg");
}

.banner-background2 {
  background-image: url("../../assets/jpg/banner2.jpg");
}

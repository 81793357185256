.search-car-wapper {
  background-color: #e1ecf9;
}

.search-car-heading-color {
  color: #073b7c;
}

.home-page-search-box {
  .ant-input,
  .ant-input-number-input,
  .ant-input-number {
    height: 38px;
  }
  .ant-select-selector {
    min-height: 38px;
  }

  .ant-input-number {
    width: 100%;
  }
}
.home-search-btn {
  @media (max-width: 768px) {
    padding: 1rem 0;
  }
}

.h-min {
  height: min-content;
}

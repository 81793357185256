.send-email-modal {
  .ant-modal-header {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  .ant-modal-content {
    border-radius: 12px;
  }

  .ant-modal-body {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .anticon-close {
    color: #2168ba !important;
    font-weight: 700 !important;
  }

  .close-line-height {
    line-height: 10px;
  }

  .message-subject {
    font-size: small;
    font-weight: bold;
  }

  .message-comments {
    font-size: medium;
  }
  .unread-message-row {
    background-color: #ff6868;
    .ant-table-column-sort {
      background: transparent;
    }
  }
}

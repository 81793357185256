.table-ant-table-content {
  width: max-content; min-width: 100%; table-layout: auto;
}

.ant-table-container-hidden {
  overflow: auto hidden;
}

.payment-settings-table-credit-score-vehicle-age-years thead tr .title-hidden {
  display: none;
}

.payment-settings-table-credit-score-vehicle-age-rates thead tr .title-hidden {
  display: none;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
  padding: 4px;
}

.ant-form-item {
  margin: 2px;
}
.banner {
  display: flex;
  align-items: start;
  height: calc(80vh - 20px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right center;
}

.banner-background-sell1 {
  background-image: url('../../assets/sellYourCar.png');
}

.banner-background2 {
  background-image: url('../../assets/jpg/banner2.jpg');
}

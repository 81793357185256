.verification-expired {
  &-table {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 3px;
    text-align: center;
    -webkit-box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.06);
    -moz-box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.06);
  }

  &-title {
    color: #1e1e2d;
    font-weight: 500;
    margin: 0;
    font-size: 32px;
    font-family: "Rubik", sans-serif;
  }

  &-line {
    display: inline-block;
    vertical-align: middle;
    margin: 29px 0 26px;
    border-bottom: 1px solid #cecece;
    width: 100px;
  }

  &-description {
    color: #c9b23c;
    font-size: 35px;
    line-height: 24px;
    margin: 0;
  }
}

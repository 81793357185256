.price-comparison:hover .comparison-content {
  max-height: 180px;
}

.comparison-content {
  max-height: 0;
}

.vehicle-card-entry {
  .vehicle-card-body {
    position: relative;
  }
  .vehicle-card-thumbnail {
    overflow: hidden;
    position: relative;
    display: block;
    padding-top: 75%;
    img {
      transition: 0.3s all ease;
      transform: scale(1);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.stock-image {
  width: 50px !important;
  transform-style: unset !important;
  -webkit-transform-style: unset !important;
}

.ant-table-thead > tr > th {
  position: relative;
  color: black;
  font-weight: 500;
  text-align: left;
  background: white;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s ease;
  opacity: 50%;
}

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: #e9f2fb;
}

.select-sortby-dashboard {
  font-size: 16px;
  color: rgb(171, 177, 186);
  font-family: "Myriad Pro";
  line-height: 1.2;
  text-align: left;
  -moz-transform: scale(0.97, 1);
  -webkit-transform: scale(0.97, 1);
  -ms-transform: scale(0.97, 1);
}
.custom-deal {
  .ant-table-tbody {
    padding: 0 !important;
  }
  .ant-table-tbody > tr > td {
    padding: 0 !important;
  }
}

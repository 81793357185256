button:disabled,
button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

button:disabled {
  pointer-events: none;
}

.custom-antd-label > .ant-form-item-label > label {
  font-size: 16px;
  font-weight: 600;
}

.ant-form .ant-form-item .ant-form-item-control,
.ant-form .ant-form-item .ant-form-item-label {
  flex: 0 0 auto;
}

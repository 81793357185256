.formContainer form{
  width: 50%;
  margin: 0 auto;
}
#form-alert-message{
  display: none;
  background-color: #bfffbb;
  border-radius: 20px;
  padding: 20px;
  width: 50%;
}
#form-alert-message-error {
  display: none;
  background-color: #b10808;
  border-radius: 20px;
  padding: 20px;
  width: 50%;
}

#form-alert-message a  {
  color: #225baa;
}

#form-alert-message-error a {
  color: #000;
}
#form-alert-message h1 {
  font-size: 23px;
}
#form-alert-message h3 {
  font-size: 16px;
}

@media only screen and (max-width: 600px) {
  .formContainer form , #form-alert-message, #form-alert-message-error {
    width: 100%;
  }
}

.formContainer h2 {
text-align: center;
font-size: 33px;
font-weight: bold;
}
.form-contact label{
  font-size: 1.2rem;
}
.formContainer {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 170px;
    flex-direction: column;
}
.contactFormInput , .contactFormInputBtn {
  width: 100%;
  padding: 3px;
  border: none;
  font-size: 1.2rem;
  border: 1px solid lightgrey;
  border-radius: 10px;
}

.contactFormInputBtn {
  background-color: #225baa;
  font-size: 18px;
  color: #ffff;
  padding: 10px;
  border: none;
}
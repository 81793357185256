.claim-header {
  background-color: #eee2de;

  .logo {
    width: 80%;
    margin: auto;
  }

  .mobile.logo {
    width: auto;
    max-width: inherit;
    height: 70px;
  }
}

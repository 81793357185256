.finance-cash-tabs .ant-tabs-nav-list {
  width: 100%;

  .ant-tabs-tab {
    width: 50%;
    display: flex;
    justify-content: center;
    color: black;
    font-size: 1.2rem;
    font-weight: bold;
    background: #e5e5e5;
    border-radius: 8px 8px 0 0 !important;

    &.ant-tabs-tab-active {
      background: white;
      border-color: #e5e5e5;
      border-bottom-color: #fff;
      padding: 3px 16px 6px;

      .ant-tabs-tab-btn {
        color: black;
      }
    }
  }
}
.ant-tabs-nav-operations{
  display: none!important;
}
.slick-slide {
  height: inherit !important;
  .justify-between {
    display: flex !important;
  }
  & > div {
    margin-right: 7px;
    height: 100% !important;
  }
}
.slider-slick {
  .slick-track {
    display: flex !important;
  }
}

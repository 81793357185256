.claim-your-business {
  .ant-checkbox .ant-checkbox-inner {
    background-color: #ffffff !important;
    height: 20px;
    width: 20px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #2168ba !important;
    border-color: #2168ba !important;
  }

  ::-webkit-input-placeholder {
    font-style: italic;
  }
  :-moz-placeholder {
    font-style: italic;
  }
  ::-moz-placeholder {
    font-style: italic;
  }
  :-ms-input-placeholder {
    font-style: italic;
  }

  span.ant-radio + * {
    font-size: 20px;
  }

  .ant-form-item-label > label {
    font-weight: 600;
    color: #dfdfe7;
  }

  .ant-checkbox + span {
    line-height: normal;
  }

  .pricing-table-set-up-column {
    width: 100px;
  }

  .pricing-table-monthly-column {
    width: 120px;
  }

  .pricing-table-checkbox-column {
    padding-left: 10px;
    width: 60px;
  }

  @media (max-width: 1024px) {
    .container {
      padding-left: 0px;
      padding-right: 0px;
    }

    .pricing-table-set-up-column {
      width: 55px;
    }

    .pricing-table-monthly-column {
      width: 60px;
    }

    .pricing-table-checkbox-column {
      width: 15px;
    }
  }
}

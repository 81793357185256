.customer-dashboard-logo {
  float: left;
  margin-top: -20px;
  margin-bottom: 20px;
  width: 60%;
}

.mobile.customer-dashboard-logo {
  width: auto;
  max-width: inherit;
  height: 55px;
}

.customer-dashboard.mobile-menu-logo {
  width: 80%;
}

.mobile-img {
  &.flex-row {
    &.justify-between {
      .w-auto {
        width: 90%;
      }
    }
  }
}

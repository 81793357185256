.filter-buy.ant-collapse {
  border: none;
  background: white;
}

.filter-buy.ant-collapse .ant-collapse-content {
  border: none;
}

.filter-buy .ant-collapse-header,
.filter-buy .ant-collapse-content-box {
  padding: 15px 0 !important;
}

.filter-buy .ant-collapse-header {
  flex-direction: column;
}

.filter-buy .ant-collapse-header .ant-collapse-extra {
  margin-left: unset !important;
  margin-top: 6px;
}

.filter-buy .ant-collapse-header .anticon {
  right: 0 !important;
  top: 20px !important;
  transform: unset !important;
}

.ant-slider-handle {
  border: none;
  background-color: #2676d9;
}

.ant-slider-track,
.ant-slider:hover .ant-slider-track {
  background-color: #2676d9;
}

.ant-checkbox + span {
  line-height: 16px;
  margin-top: 7px;
}

.zip-code-input {
  padding: 5px 10px;
}

.similar-deals-group.ant-radio-group {
  font-size: inherit;
}

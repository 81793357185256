.owl-carousel {
  display: flex !important;
}

.owl-carousel .owl-nav {
  display: none;
}

.owl-stage,
.owl-item {
  height: 100%;
}

.owl-carousel-container .btn-preview {
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  color: #868686 !important;
  font-size: 100px;
  z-index: 20;
  outline: none;
}

.owl-carousel-container .btn-next {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  color: #868686 !important;
  font-size: 100px;
  z-index: 20;
  outline: none;
}

.owl-carousel .owl-dots {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
}

.owl-carousel .owl-dots .owl-dot.active span {
  background: #2676d9 !important;
}

@media (min-width: 992px) {
  .owl-carousel .owl-dots {
    bottom: 4rem;
  }

  .owl-carousel-container .btn-preview {
    left: 1.5rem;
  }

  .owl-carousel-container .btn-next {
    right: 1.5rem;
  }
}

.verify-your-account-container {
  .verify-account-modal-alert {
    .ant-modal-close-x {
      color: #1890ff;
    }
  }

  .ant-select-selector {
    border-radius: 10px !important;
  }

  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
    border-color: lightgray !important;
    box-shadow: none !important;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: lightgray !important;
    box-shadow: none !important;
  }

  .address-input-border {
    outline: 0;
    border-width: 0 0 2px !important;
    background: none !important;
  }

  input:disabled {
    background: none !important;
  }

  .address-select-border {
    .ant-form-item-control {
      height: 0px !important;
      border: none;
    }
  }

  .ant-select-selection-item {
    color: black !important;
  }
}

.google-map-container {
  width: inherit;
  height: 250px;
  outline: 0;
  border-width: 0 0 2px !important;
}

.price-vehicle {
  right: none;
}

.arrow-price {
  right: none;
}

.border-price {
  width: 75px;
  right: none;
}

.market-rate {
  background-color: #f7f8f8;
  clear: both;
}

.market-rate .custom-c-border {
  border-bottom: 4px solid #028483;
}

.market-rate.m-detail {
  padding: 0 1rem 1rem;
}

.market-rate .cont {
  padding-top: 2.5rem;
}

.market-rate .custom-c-price {
  background-color: #028483;
  padding: 0 5px;
  border-radius: 20px;
  color: #fff;
  display: flex;
  font-size: 70%;
  top: 12px;
}

.market-rate .custom-arrow-price {
  bottom: 1px;
  border-color: #028483 transparent transparent;
  border-style: solid;
  border-width: 0.5rem 0.3rem 0;
  top: 29px;
}

.market-rate .line {
  border-bottom: 4px solid #dadada !important;
}

.market-rate .low-color {
  color: #939291;
}

.fontSize9 {
  font-size: 9px !important;
}

.fontSize10 {
  font-size: 10px !important;
}

.fontSize12 {
  font-size: 12px;
}

.fontSize14 {
  font-size: 14px;
}

.fontSize16 {
  font-size: 16px;
}

.fontSize18 {
  font-size: 18px;
}

.fontSize20 {
  font-size: 20px;
}

.fontSize22 {
  font-size: 22px;
}

.fontSize24 {
  font-size: 24px;
}

.fontSize-1-4 {
  font-size: 1.4rem;
}

.fontSize-2-4 {
  font-size: 2.4rem;
}

.btn-compare {
  background-color: #f7f8f8;
  font-size: 11px;
  padding: 5px 10px 2px 15px;
  color: #868686;
  font-weight: 400;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.popover-tooltip {
  position: absolute;
  inset: auto auto 0px 0px;
  margin: 0px;
  transform: translate3d(673.6px, -392px, 0px);
}

.popover-arrow {
  position: absolute;
  left: 0px;
  transform: translate3d(142.4px, 0px, 0px);
}

.slider-line-left {
  height: 10px;
  border-left: 3px solid lightgray;
  top: 0;
}

.slider-line-middle {
  height: 10px;
  border-left: 3px solid lightgray;
  left: 50%;
  margin-left: -3px;
  top: 0;
}

.slider-line-right {
  height: 10px;
  border-left: 3px solid lightgray;
  left: 100%;
  margin-left: -3px;
  top: 0;
}

.dealership-detail-container {
  .ant-input-status-error {
    border-color: lightgray !important;
    box-shadow: none !important;
  }
  .ant-input-status-success {
    border-color: lightgray !important;
    box-shadow: none !important;
  }

  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
    border-color: lightgray !important;
    box-shadow: none !important;
  }

  .ant-select-status-success.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: lightgray !important;
    box-shadow: none !important;
  }
}
